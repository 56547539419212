import { useEffect, useState } from "react"
import { GetProspectiveDetails } from "../api/application";
import { getCurrentUser } from "../components/services/auth/authService";

const useProspective = () => {
    const [data, setData] = useState()
    const [staging, setStaging] = useState()
    const [loading, setLoading] = useState(false);
    const user = getCurrentUser();

    useEffect(() => {
        const getAdmission = async () => {
            setLoading(true);
            try {
              const { data } = await GetProspectiveDetails(user.admission_id);
              // console.log("Prospective Details", data);
              setStaging(data.stage);
              setData(data)
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          };
          getAdmission()
    }, [staging])

    return {data, loading, staging}
}

export default useProspective