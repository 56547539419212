import React, { useEffect, useState, useRef } from "react";
import Logo from "../../assets/images/dspg_logo.png";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import api from "../../api/api";
import { useReactToPrint } from "react-to-print";
import useDetails from "../../hooks/useDetails";
import useAdmission from "../../hooks/useAdmission";

const ApprovedAdmissionLetter = () => {
  const { data, staging: stage, loading } = useAdmission();
  // const [details, setDetails] = useState(data?.admin_response)
  const details = data?.admin_response[0]?.approved_program

  console.log("admission details",details)

  const componentRef = useRef();

  const pageStyle = `
    @page {
      size: 210mm 297mm;
      margin: 0rem 8rem;
    }
  `;
  const printAcceptanceLetter = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "AdmissionLetter",
    onAfterPrint: () => console.log("Admission letter printed"),
    pageStyle: pageStyle,
  });

  return (
    <>
    {((stage === 'approved')) &&
    <>
        <div
          className=""
          id="adm_letter"
          style={{ minHeight: "100vh" }}
        >
          <div className="">
            <div className="container-fluid">
              <div className="card" ref={componentRef}>
                <div className="body receipt">
                  <center style={{ marginBottom: "2rem" }}>
                    <h3 style={{ marginBottom: "0px" }}>
                      DELTA STATE POLYTECHNIC
                    </h3>
                    <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                      OGWASHI-UKU
                    </p>
                    <div style={{ height: "60px", width: "60px" }}>
                      <img
                        alt="logo"
                        src={Logo}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <h4 style={{ fontStyle: "italic" }}>
                      OFFICE OF THE REGISTRAR
                    </h4>
                  </center>
                  <center>
                    <h3>{data?.entrySession} {details?.program} ADMISSION</h3>
                  </center>
                  <div style={{ marginBottom: "1rem" }}>
                    <p>
                      Congratulations! You have been offered Provisional Admission
                      into the <span>{(details?.program).toUpperCase()}</span> Programme of the
                      institution with the following details:
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="qr-code">
                      {data && (
                        <QRCode value={data?.email} size={100} />
                      )}
                    </div>
                    <div style={{ height: "100px", width: "100px" }}>
                      <img
                        alt="passport"
                        src={data?.photo}
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                  <p>
                    SECTION A:{" "}
                    <span style={{ fontWeight: "600" }}>PERSONAL DETAILS</span>
                  </p>
                  <table
                    style={{
                      border: "none !important",
                      width: "600px",
                      marginBottom: "2rem",
                    }}
                  >
                    <tr>
                      <td>1</td>
                      <td>JAMB Reg. Number</td>
                      <td>{data?.jambno}</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Application Number</td>
                      <td>{data?.username}</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Names</td>
                      <td>
                        {data?.surname +
                          " " +
                          data?.othername}
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Gender</td>
                      <td>{data?.gender}</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>State of origin</td>
                      <td>{data?.state_origin}</td>
                    </tr>
                  </table>

                  {/* section b now */}

                  <p>
                    SECTION B:{" "}
                    <span style={{ fontWeight: "600" }}>ACADEMIC DETAILS</span>
                  </p>
                  <table style={{ border: "none !important", width: "600px" }}>
                    <tr>
                      <td>1</td>
                      <td>Institution</td>
                      <td>DELTA STATE POLYTECHNIC OGWASHI-UKU</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Faculty/School</td>
                      <td>{details?.faculty_name}</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Department</td>
                      <td>{details?.department}</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Programme</td>
                      <td>{details?.program}</td>
                    </tr>
                    {/* <tr>
                      <td>5</td>
                      <td>Programme Type</td>
                      <td>{data?.program_type}</td>
                    </tr> */}
                  </table>
                  <div className="mt-4">
                    <h3>NOTE</h3>
                    <p>
                      You are expected to pay the sum of{" "}
                      <span style={{ fontWeight: "600", fontStyle: "italic" }}>
                        N{"amount"}
                      </span>{" "}
                      as ADMISSION ACCEPTANCE FEE on or before [Deadline], else
                      your admission will be{" "}
                      <span style={{ fontWeight: "600" }}>WITHDRAWN</span>.
                    </p>
                    <p>
                      Please look out for information on resumption date on the
                      Institution website
                    </p>
                  </div>
                </div>
              </div>
              <center>
                <button
                  className="btn btn-raised btn-primary waves-effect"
                  onClick={printAcceptanceLetter}
                >
                  Print Admission Letter
                </button>
              </center>
            </div>
          </div>
        </div>

    </>
    }
    </>
  );
};

export default ApprovedAdmissionLetter;
