import { useEffect, useState } from "react"
import { GetProspectiveStage } from "../api/application";
import { getCurrentUser } from "../components/services/auth/authService";

const useProspectiveInfo = () => {
    const [data, setData] = useState()
    const [staging, setStaging] = useState()
    const [student_id, setStudentID] = useState()
    const [loading, setLoading] = useState(false);
    const [coc, setCoc] = useState('')
    const [status, setStatus] = useState()
    const user = getCurrentUser();

    useEffect(() => {
        const getAdmission = async () => {
            setLoading(true);
            try {
              const data = await GetProspectiveStage(user.admission_id);
              // console.log("Prospective stage/COC", data);
              setCoc(data.coc)
              setStaging(data.data.stage);
              setStudentID(data.data.student_id);
              setStatus(data.data.adm_clearance_status);
              setData(data.data)
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          };
          getAdmission()
    }, [])

    return {data, loading, staging, coc, status, student_id}
}

export default useProspectiveInfo