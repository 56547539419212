import React from "react";

const StudentInfo = ({data}) => {
    // const {payment} = usePayments()
    // const schoolFee = payment?.school_fees_payment?.payment_status

    const details = data?.admin_response[0]?.approved_program

  return (
    <>
      <p className="breadcrumb-item active">Information</p>

      <div class="form-group row">
        <div class="col-md-4">
          <label>Application Number</label>
          <input
            type="number"
            placeholder={data?.username}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Name</label>
          <input
            type="text"
            placeholder={data?.surname + " " + data?.othername}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Academic Session</label>
          <input
            type="text"
            placeholder={data?.entrySession}
            class="form-control"
            disabled
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-4">
          <label>Programme Type</label>
          <input
            type="number"
            placeholder={details?.program}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Study Mode</label>
          <input
            type="text"
            placeholder={data?.studyMode}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-4">
          <label>Entry Mode</label>
          <input
            type="text"
            placeholder={data?.entryMode}
            class="form-control"
            disabled
          />
          {/* <input type="text" placeholder={schoolFee === "success" ? "PAID" : "NOT PAID"} class="form-control" disabled /> */}
        </div>
      </div>
      <div class="form-group row">
        <div class="col-md-6">
          <label>Department</label>
          <input
            type="number"
            placeholder={details?.department}
            class="form-control"
            disabled
          />
        </div>
        <div class="col-md-6">
          <label>Faculty</label>
          <input
            type="text"
            placeholder={details?.faculty_name}
            class="form-control"
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default StudentInfo;
