import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api/api";
import { GetApplicationFee, InitiateChangeOfCoursePayment } from "../../../api/application";
import { numberWithCommas } from "../../../common";
import { getCurrentUser } from "../../../components/services/auth/authService";
import useProspectiveInfo from "../../../hooks/useProspectiveInfo";
import useProspectivePayment from "../../../hooks/useProspectivePayment";

const ApprovedCOC = () => {
  const [file, setFile] = useState();
  const [newCourse, setNewCourse] = useState("");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState();
  const location = useLocation();
  const [paymentDetails, setPaymentDetails] = useState()

  const { data, staging: stage, coc } = useProspectiveInfo();
  const {courseChangeStatus} = useProspectivePayment()

  const user = getCurrentUser();
  const token = user?.access;

  const successProp = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };
  const payChangeOfCourseFee = async (e) => {
    e.preventDefault()
    try {
      setLoading(true);
      const { data } = await InitiateChangeOfCoursePayment({
        admission_no: user.admission_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made")
        setLoading(false)
      } else {
        toast.success("Initiating payment");
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Initiating payment failed!");
    }
  };

  const getPaymentDetails = async () => {
    try {
      const { data } = await api.post("/admissions/get-prospective-payment", {
        payment_name: "change of course"
      });
      setPaymentDetails(data.data);
    } catch (error) {}
  }

  const getDepartment = async () => {
    try {
      const { data } = await api.get("/department/", {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      console.log(data.results);
      setCourses(data.results);
    } catch (error) {}
  };
  const applyChangeOfCourse = async (e) => {
    e.preventDefault()
    try {
      const { data } = await api.post("/prospectives/change-course", {
        new_course: newCourse
      });
      console.log(data);
      toast.success("Application successful")
      window.location.reload()
    } catch (error) {
      toast.error("Error occured. Try again")
    }
  };

  useEffect(() => {
    getDepartment();
    getPaymentDetails()
  }, []);

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/admission/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  if (stage === "submitted") {
    return (
      <Navigate
        to={{
          pathname: `/admission`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  return (
    <div class="page-wrapper">
      {data && (
        <div class="page-content-tab">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <div class="page-title-box">
                  <div class="float-right">
                    <ol class="breadcrumb">
                      <li class="breadcrumb-item active">Documents</li>
                    </ol>
                  </div>
                  <h4 class="page-title">Change of Course</h4>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="auth-logo-text">
                      {/* <h5 class="text-danger mb-2">
                        Make payment to continue Change of Course process.
                      </h5> */}
                    </div>
                    {courseChangeStatus?.payment_status === 'success' ?
                    <div className="row">
                      {coc === 'no' &&
                      <div className="col-lg-6">
                        <form onSubmit={applyChangeOfCourse}>
                          <div class="mb-3">
                            <div className="">
                              <label>Departments Available</label>
                              <select
                                class="form-control"
                                value={newCourse}
                                onChange={(e) => setNewCourse(e.target.value)}
                                required
                              >
                                <option value="">--Select name--</option>
                                {courses?.map(({ id, department }) => (
                                  <option key={id} value={id}>
                                    {department}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div class="card-body">
                            <button
                              class="btn btn-sm btn-gradient-primary text-white px-4 mb-0"
                              type="submit"
                              disabled={loading}
                            >
                              Apply for Course
                            </button>
                          </div>
                        </form>
                      </div> 
                        }
                        {coc === 'yes' &&
                      <div class="alert dspg-success col-lg-6" role="alert">
                      <h4 class="alert-heading">Change of Course Application Successful!</h4>
                      <p>Watch your dashboard for response from administration</p>
                    </div>
                      }
                    </div>
                    :
                    <div className="row">
                      <div className="col-lg-6">
                        <form onSubmit={payChangeOfCourseFee}>
                          <div class="col-md-6">
                            <br/>
                            <h6>Payment Information</h6>
                            <label className="mb-0">Amount</label>
                            <div class="d-flex flex-row align-items-center">
                      <sup class="dollar font-weight-bold">₦</sup>
                      <span class="amount ml-1 mr-1">{paymentDetails && numberWithCommas(paymentDetails?.amount)}</span>
                    </div>
                          </div>
                          <div class="card-body">
                            <button
                              class="btn btn-sm btn-success text-white px-4 mb-0"
                              type="submit"
                              disabled={loading}
                            >
                              Proceed to Payment
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovedCOC;
