import React, { useEffect, useState } from 'react'
import Footer from '../../../components/Footer'
import api from '../../../api/api'
import { toast } from 'react-toastify'
import useProspective from '../../../hooks/useProspective'
import useProspectivePayment from '../../../hooks/useProspectivePayment'
import StudentInfo from '../../application/components/StudentInfo'
import { Navigate, useLocation } from 'react-router'
import useProspectiveInfo from '../../../hooks/useProspectiveInfo'

const Accomodation = () => {
    const [selectedRoom, setSelectedRoom] = useState('')
    const [rooms, setRooms] = useState([])
    const [loading, setLoading] = useState()
    const [btn, setBtn] = useState('Book')

    const {data:details} = useProspective()
    const {acceptanceStatus, resultStatus} = useProspectivePayment()

    const {staging:stage} = useProspectiveInfo()
  const location = useLocation()


    const getRooms = async () => {
        try {
            const {data} = await api.get('/hostel/rooms')
            console.log("available rooms", data)
            setRooms(data?.results)
        } catch (error) {
            console.log(error)
        }
    }

    const applyForAccomodation = async (e) => {
      e.preventDefault()
      if (acceptanceStatus?.payment_status !== 'success' && resultStatus?.payment_status !== 'success') return toast.warning('Please pay Acceptance and Result verification fees first!')
        setLoading(true)
        setBtn('Loading...')
        try {
            const {data} = await api.post("/hostel/select-accommodation", {
                room_type:selectedRoom
            })
            console.log("Hostel response",data.data)
            const {status, authorization_url} = data.data
            if ( status && status === 'success') {
                toast.info('Hostel Payment Already Made')
                setLoading(false)
                setBtn('Book')
            } else {
                toast.success('Initializing Payment')
                setLoading(false)
                setBtn('Book')
                window.location.replace(data?.data?.authorization_url)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            setBtn('Failed, try again!')
            
        }
    }

    useEffect(() => {
        getRooms()
    }, [])

    if (stage === null) {
        return (
          <Navigate
            to={{
              pathname: `/admission/payment`,
              state: { from: location },
            }}
            replace
          />
        );
      }
    
  return (
    <div class="page-wrapper">
      {details && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Accommodation</li>
                  </ol>
                </div>
                <h4 class="page-title">Hostel Accommodation</h4>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="tab-content detail-list" id="pills-tabContent">
                <div class="tab-pane fade show active" id="general_detail">
                  <div class="row">
                    <div class="col-lg-12 col-xl-9">
                      <div class="card">
                        <div class="card-body">
                          <div class="">
                            {(acceptanceStatus?.payment_status === 'success' && resultStatus?.payment_status === 'success') ?
                            <>
                            <StudentInfo data={details}/>
                            <form onSubmit={applyForAccomodation}>
                            <div class="mb-3">
                              <div className="">
                                <label>Available Accomodation</label>
                              <table className='mb-5'>
                                  <th className='dspg-info'>Name of Accommodation</th>
                                  <th className='dspg-info'>Number of Occupants</th>
                                  <th className='dspg-info'>Amount</th>
                                  <tbody>
                                      {rooms?.map((room) => (
                                          <tr key={room?.id}>
                                              <td>{room?.name}</td>
                                              <td>{room?.num_of_occupants}</td>
                                              <td>{room?.amount}</td>
                                          </tr>
                                      ))}
                                  </tbody>
                              </table>
                                <select
                                  class="form-control"
                                  value={selectedRoom}
                                  onChange={(e) => setSelectedRoom(e.target.value)}
                                  required
                                >
                                  <option value="">--Select Booking--</option>
                                      {rooms?.map(({ id,name }) => (
                                      <option key={id} value={id}>
                                          {name}
                                      </option>
                                      ))}
                                </select>
                              </div>
                            </div>
                            <div class="card-body">
                              <button
                                class="btn btn-sm btn-gradient-success text-green px-4 mb-0"
                                type="submit"
                                disabled={loading}
                              >
                                {btn}
                              </button>
                            </div>
                          </form>
                          </>
                            :
                            <div className='alert dspg-danger'><b>Note! </b>You have to pay Acceptance and Result Verification Fees to be eligible for Accommodations</div>
                         }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      }
    </div>
  )
}

export default Accomodation