import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavHeader from "./components/NavHeader";
import NewsMarquee from "./components/NewsMarquee";
import SliderCarousel from "./components/SliderCarousel";
import dspgBackground from "../../assets/images/dspg_gate.jpg"
import studyImage from "../../assets/images/study_image.png"
import logo from "../../assets/images/dspg_logo.png"
import ataLogo from "../../assets/images/ATA.png"
import Marque from "react-fast-marquee"

const ApplicationLandingPage = () => {
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `url(${dspgBackground})`,
    height: "100% !important",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity:'1'
}
  const backgroundStyle2 = {
    backgroundImage: `url(${studyImage})`,
    height: "100% !important",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity:'1'
}
  return (
    <>
      <div data-layout="horizontal-tab">
        <NavHeader />
          <div class="px-3">
            <div class="row v-100 d-flex">
              <div class="h-100 col-xs-12 col-sm-6 col-md-7 position-relative" style={{ position: 'relative' }}>
                <div className="h-100" style={backgroundStyle}>
                <div style={{ content: '', backgroundSize: 'cover', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,backgroundColor: "rgba(255,255,255,.9)"}}></div>
                  <div class="card-body" style={{ position: 'relative', zIndex: 1, paddingTop:'5rem' }}>
                    <div class="px-3">
                      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
                        <div style={{height:'200px', marginBottom:'2rem'}}>
                          <img className="h-100" src={logo} alt="dspg_logo"/>
                        </div> 
                        <SliderCarousel/>
                        <div className="d-flex flex-column align-items-center" style={{marginTop:'4rem'}}>
                          <h6 className="black">Powered by</h6>
                          <div style={{height:'30px'}}>
                            <img className="h-100" src={ataLogo} alt="atasoftec"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*Footer */}
                {/* take off marquee */}
                {/* <div className="p-2 d-flex align-items-center " style={{ position: 'relative', zIndex: 1, backgroundColor:'#0C0F2E'}}>
                  <Marque className="text-white" direction="right">2023/2024 Admissions currently ongoing. Apply for the next session and get started fulfulling your dreams</Marque>
                </div> */}
              </div>
              <div class="col-sm-6 col-md-5 center-image">
              <div className="vh-100" style={backgroundStyle2}>
                  <div class="card-body">
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationLandingPage;
